import axios from 'axios'
import router from '@/router'
import showCustomToast from './showCustomToast'
// 创建 axios 实例
const service = axios.create({
  // baseURL: 'http://192.168.110.100:3000'
  baseURL:'http://api.senyagroup.com'
  // withCredentials:false
})
// 请求拦截和响应拦截 
// 添加请求拦截器
service.interceptors.request.use(function (config) {
  // console.log(config)

  // 在发送请求之前做些什么
  // config.headers = config.headers || {};
  if (localStorage.getItem("token")) {
    // console.log(location.href)
    config.headers.Authorization = localStorage.getItem("token") || "";
  }
  return config
}, function (error) {
  // 对请求错误做些什么
  // return Promise.reject(error)
})

// 添加响应拦截器
service.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  // console.log(response.headers.getAuthorization())       
  // console.log(response)

  if (response.status === 401) {
    localStorage.clear()
    sessionStorage.clear()
    router.push({ path: '/login'})  //如果返回401 直接跳转登录界面，20231024加入代码
  } else {
    const { authorization } = response.headers
    // console.log(authorization)
    authorization && localStorage.setItem('token', authorization) //

    return response
  }
}, async function (error) {
  // 对响应错误做点什么
  // console.log('error', error.response.status)
  // console.log('error', error)
  if (error.code === 'ERR_NETWORK') {
    await showCustomToast("网络连接错误，请稍后重试！")
    localStorage.clear()
    sessionStorage.clear()
    router.push({ path: '/login' })
    return
  }
  if (error.code === 'ERR_BAD_REQUEST') {
    await showCustomToast("token失效，请重新登录！")
    localStorage.clear()
    sessionStorage.clear()
    router.push({ path: '/login' })
    return
  }
  // console.log('status',error.response.status)
  switch (error.response.status) {
    case 401:
      localStorage.clear()
      sessionStorage.clear()
      router.push({ path: '/login' })
      break;
    case 404:
      localStorage.clear()
      sessionStorage.clear()
      await showCustomToast("api不存在，请检查接口地址是否正确！")
      router.push({ path: '/login' })
      break
    case 500:
      localStorage.clear()
      sessionStorage.clear()
      await showCustomToast("内部服务器错误，请联系系统管理员！")
      router.push({ path: '/login' })
      break
    default:
      localStorage.clear()
      sessionStorage.clear()
      await showCustomToast("未知错误，请稍后重试！")
      router.push({ path: '/login' })
      break
  }

  // return Promise.reject(error) //20231024
})

export default service

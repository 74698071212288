<template>
  <!-- <nut-watermark v-if="true" class="mark1" :z-index="1" :content="markText" font-color="rgba(0, 0, 0, .06)" ></nut-watermark> -->
  <router-view />
</template>

<script setup>
import { ref, reactive, onMounted, provide } from 'vue';
import link from './api/link';


// 全局变量设置及赋值
let freePoint = ref(8000);//默认
let useBonusRate = ref(0.1);//默认
onMounted(() => {
  // getShareInfo() //微信api调用
  disabledScale() //禁止移动端页面放大缩小
  try {
    link({ url: `/Apps/basedata` }).then(res => {
      // console.log(res)
      if(res.data.data.length){
        freePoint.value = res.data.data[0].freePoint
        useBonusRate.value = res.data.data[0].useBonusRate
        // console.log(freePoint.value, useBonusRate.value)
      }
    })
  } catch (error) {

  }

})
//跨组件提供全局变量
provide('freeCarry', freePoint); // 免物流基础金额
provide('bonusPer', useBonusRate); //充值赠送金每单抵扣百分比


// 分享部分
// url 是我们要分享页面的链接，需要传递到后端进行加密签名（后端的代码我们待会儿看）
// getSing方法是后端进行签名的方法，安全起见，所有config初始化中的关键信息，都从后端往回拿。
// jsApiList是我们需要实现的功能的方法列表，逗号隔开。
// const getShareInfo = () => {
//   //获取url链接（如果有#需要这么获取）
//   //   var url = encodeURIComponent(window.location.href.split("#")[0]);
//   var url = encodeURIComponent(window.location.href);
//   // console.log(url)
//   //获取url链接（如果没有#需要这么获取）
//   // var url = encodeURIComponent(window.location.href);
//   link({
//     url: '/wx/sign',
//     method: 'post',
//     data: { url:url }
//   }).then(res => {
//     console.log(res)
//     // return
//     wx.config({
//       debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
//       appId: res.data.appId, // 必填，公众号的唯一标识
//       timestamp: res.data.timestamp, // 必填，生成签名的时间戳
//       nonceStr: res.data.noncestr, // 必填，生成签名的随机串
//       signature: res.data.signature, // 必填，签名
//       jsApiList: [
//         'updateTimelineShareData',
//         "updateAppMessageShareData",
//         'onMenuShareAppMessage',  //旧的接口，即将废弃
//         'onMenuShareTimeline', //旧的接口，即将废弃
//       ] // 必填，需要使用的 JS 接口列表
//     });
//     wx.ready(() => {
//       var shareData = {
//         title: '颀丽管家系统,品牌内部管理系统，需要账号密码登录', // 分享标题
//         desc: '流程审批、信息门户、企业应用、工作平台、一站式门店服务', // 分享描述
//         link: 'http://senyagroup.com', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致 192.168.110.100:8080
//         imgUrl: 'http://senyagroup.com/uploads/images/qilizzsflogo.png', // 分享图标
//       };
//       //自定义“分享给朋友”及“分享到QQ”按钮的分享内容
//       wx.updateAppMessageShareData(shareData);
//       //自定义“分享到朋友圈”及“分享到 QQ 空间”按钮的分享内容（1.4.0）
//       wx.updateTimelineShareData(shareData);
//     });
//   });
// }

// const scanCode = () => {
//   wx.scanQRCode({
//     needResult: 0, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
//     scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
//     success: function (res) {
//       var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
//       // console.log(result)
//     }
//   });
// }

const disabledScale = () => {
  document.addEventListener('touchstart', function (event) {
    if (event.touches.length > 1) {
      event.preventDefault();
    }
  }, { passive: false });

  document.addEventListener('gesturestart', function (event) {
    event.preventDefault();
  });
}




</script>
<style>
.wrap {
  width: 100%;
  height: 240px;
  display: block;
  background: #fff;
}

.wrap>img {
  width: 100%;
}

.mark1 {
  width: 100%;
  top: 50px;
}

.nut-button {
  margin-right: 10px;
}
</style>

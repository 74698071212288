import { showToast } from 'vant';
function showCustomToast(message,duration=2000){
    return new Promise(resolve=>{
        showToast({message:message,duration:duration});
        setTimeout(resolve,duration)
    },reject=>{
        // console.log('reject')
    })
}

export default showCustomToast
import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import RoutesConfig from './config'
import store from '@/store/index'
// import { from } from 'core-js/core/array'
import link from '@/api/link'
const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/wxlogin',
    name: 'wxlogin',
    component: () => import('../views/wx/wx_login.vue')
  },
  {
    path: '/wxbind',
    name: 'wxbind',
    auth: false,
    component: () => import('../views/wx/wx_bind.vue')
  },
  {
    path: '/mainbox',
    name: 'mainbox',
    component: () => import('../views/MainBox.vue')
  }

]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL + 'h5/'),  //此模式页面刷新会丢失路由，原因未查到
  history: createWebHistory('/h5'),  //// 设置为history模式，就是路径里面没有#,  createWebHashHistory 是默认的，括号里面的就是基础路径，可以理解为项目名称，就是请求路径的基础url
  // history: createWebHashHistory(),
  routes
})


// router.beforeEach((to, from, next) => {
//   try {
//     if (to.name === 'wxlogin' || to.name === 'wxbind') next(); //配合微信验证用，不放行则取不到问号后面的参数
//     const token = localStorage.getItem('token') // 假设token存储在localStorage中
//     if (to.name === 'login' && !token) {
//       console.log(to.name, token)
//       // 如果要进入的路由不是登录页，且没有token，则重定向到登录页
//       store.commit("changeProcessData", [])
//       store.commit("changeCategoryData", [])
//       localStorage.clear()
//       sessionStorage.clear()
//       next()
//     } else if (to.path === '/login' && token) {
//       // 如果已经登录，但用户尝试访问登录页，则重定向到首页
//       next('/index')
//     } else if (!token) {
//       next({
//         path: '/login'
//       })
//     } else {
//       if (store.state.isGetRouter) {
//         // console.log(to.fullPath)
//         next()
//       } else {
//         // 删除所有的嵌套路由，然后重新加载路由
//         // mainbox  
//         router.removeRoute("mainbox")

//         initRouter()
//         next({
//           path: to.fullPath
//         })
//       }
//     }
//   } catch (error) {
//     next({
//       path: '/login'
//     })
//   }
// })

//每次路由跳转之前 原来的路由守卫
router.beforeEach((to, from, next) => {
  // console.log(to.fullPath)
  // if(to.path !== '/wxlogin' || to.path !== '/login') to.fullPath = to.fullPath + `?t=${ Date.parse(new Date())/1000}`
  // if(to.path == '/index') to.fullPath = to.fullPath + `?t=${ Date.parse(new Date())/1000}`

  if (to.name === 'wxlogin' || to.name === 'wxbind') next(); //配合微信验证用，不放行则取不到问号后面的参数

  try {
    // console.log(to.name,to.path)
    if (to.name === "login") { //改为to.path
      store.commit("changeProcessData", [])
      store.commit("changeCategoryData", [])
      localStorage.clear()
      sessionStorage.clear()
      next()
    } else {
      if (!localStorage.getItem('token')) {
        next({
          path: '/login'
        })
      } else {
        if (store.state.isGetRouter) {
          // console.log(to.fullPath)
          next()
        } else {
          // 删除所有的嵌套路由，然后重新加载路由
          // mainbox  
          router.removeRoute("mainbox")

          initRouter()
          // console.log(to.fullPath,to.path)
          next({
            // path: to.fullPath
            //2024.9.24改成如下，方便首页地址带参
            path: to.path,
            query: to.query
          })
        }
      }
    }
  } catch (error) {
    next({
      path: '/login'
    })
  }
})

// router.afterEach((to, from) => {
//   if (to.name !== 'login' && to.name !=='wxlogin' && to.name !=='wxbind' &&  to.name !=='mainbox') {
//     try {
//       const pageName = to.name
//       link({ url: `/page/visits`, method: 'post', data: {pageName:pageName} }).then(res => {
//         //处理成功
//       })
//     } catch (error) {
//       //
//     }
//   }

// })

const initRouter = () => {
  if (!router.hasRoute("mainbox")) {
    router.addRoute(
      {
        path: '/mainbox',
        name: 'mainbox',
        component: () => import('../views/MainBox.vue')
      }
    )
  }
  //加载子路由
  // 加上权限管控 按角色路由加载
  // console.log( store.state.userinfo.process)
  store.state.userinfo.process.forEach((item) => {
    // console.log( store.state.userinfo.process)
    RoutesConfig.forEach((item2) => {
      // item2.path.includes(item.url) item.url == item2.path
      if (item2.path.includes(item.url)) router.addRoute("mainbox", item2)
    })
  })

  //无权限管控  ==> 
  // console.log(RoutesConfig)
  RoutesConfig.forEach(item => {
    // console.log(item.path,item.auth)
    if (!item.auth) router.addRoute('mainbox', item)
  })

  // 注册center子路由
  router.addRoute('center',
    {
      path: '/center/pending',
      name: 'pending',
      component: () => import('../views/Pending.vue')
    }
  )
  router.addRoute('center',
    {
      path: '/center/approved',
      name: 'approved',
      component: () => import('../views/Approved.vue')
    }
  )
  router.addRoute('center',
    {
      path: '/center/share',
      name: 'share',
      component: () => import('../views/Share.vue')
    }),
    router.addRoute('center',
      {
        path: '/center/myrequest',
        name: 'myrequest',
        component: () => import('../views/MyRequest.vue')
      })

  // 改变isGetRouter状态
  store.commit("changeGeterRouter", true)
  // console.log(router.getRoutes())
}

export default router

import service from '../util/service.js'
import { showLoadingToast, closeToast } from 'vant';
// 封装相关的数据请求
const link = (params) => {
  showLoadingToast({
    message: '加载中...',
    forbidClick: true,
  });
  return new Promise((resolve, reject) => {
    service.request({
      ...params,
      // url: apiUrl.one + params.url
    }).then((res) => {
      closeToast()
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}
export default link

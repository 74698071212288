import link from '@/api/link'
import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate" //持久化引入

export default createStore({
  state: {
    // 登录信息
    userinfo:{},

    isGetRouter:false,// 路由是否已加载
    ishasCats:false,//购物车是否有产品
    proNums:0,//购物车产品数量
    cartlist:[],//购物车待结算列表
    isShowTabBar:true,//是否显示底部菜单栏

    CategoryDataList:[], //产品分类数据列表

    ProcessDataList:[], //流程数据列表
    AppsDataList:[], //可用应用非流程列表
  },
  getters: {
  },

  mutations: {
    changeShowTabBar(state,value){
      state.isShowTabBar = value
    },
    changeCartlist(state,value){
      state.cartlist = value
    },
    changeGeterRouter(state,value){
      state.isGetRouter = value
    },
    change_userinfo(state, val){

      // state.userinfo={...val}
      state.userinfo={
        ...state.userinfo,
        ...val
      }
    },
    clear_userinfo(state, val){
      state.userinfo={}
    },
    changeIshascats(state,val){
      state.ishasCats = val
    },
    addProNums(state,val){
      // console.log(state.proNums)
      state.proNums =val
    },
    changeProNums(state,val){
      state.proNums=val
    },
    changeCategoryData(state,data){
      state.CategoryDataList=data
    },
    changeProcessData(state,data){
      state.ProcessDataList=data
    },
    changeAppsData(state,data){
      state.AppsDataList=data
    }
  },
  actions: {
    //异步
    // 获取产品分类列表及产品数据
    getCategoryList(store){
        link({
          url:`/product/categorylist`
      }).then(res=>{
          // console.log(res.data)
          if(res.data.code && res.data.code===1) store.commit("changeCategoryData",res.data.data)
      })
    },
    getProcessList(store){
      link({
          url:`/process`
      }).then(res=>{
        // console.log(res)
        if(res.data.code &&  res.data.code===1) store.commit("changeProcessData",res.data.data)
        
      })
    },
    getAppsList(store){
      link({
          url:`/Apps`
      }).then(res=>{
        // console.log(res)
        if(res.data.code &&  res.data.code===1) store.commit("changeAppsData",res.data.data)
        
      })
    },
  },
  modules: {
  },
  plugins: [createPersistedState({
    paths:["userinfo","proNums"] //控制是否持久化
  })]
})

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 引入VANT 组件库
import Vant from 'vant'
import 'vant/lib/index.css'


//全局引入NutUI 京东物流组件
import NutUI from "@nutui/nutui";
import "@nutui/nutui/dist/style.css";

import { IconFont } from '@nutui/icons-vue';
import '@nutui/icons-vue/dist/style_iconfont.css';

// 引入图片点击预览组件
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'

//根据版本号清空用户缓存 作用不大
// import packageJson from '../package.json';
// let {version} = packageJson
// console.log(version)
// const VUE_APP_VERSION = version
// const vers = window.localStorage.getItem("Version");
// if(!vers) window.localStorage.setItem("Version", VUE_APP_VERSION);
// if (vers && VUE_APP_VERSION != vers) {
//     localStorage.clear();
//     sessionStorage.clear();
//     // Cookies.remove("token");
//     window.localStorage.setItem("Version", VUE_APP_VERSION);
//     location.reload()
// }

// // 清理缓存的函数
// function clearCache() {
//     console.log(window.caches)
//     if (window.caches) {
//         caches.keys().then(function (names) {
//         for (let name of names) {
//             caches.delete(name);
//         }
//         });
//     }
// }

// // 调用清理缓存的函数
// clearCache();


createApp(App)
.use(VueViewer)
.use(Vant)
.use(NutUI)
.use(IconFont)
.use(store)
.use(router)
.mount('#app')
